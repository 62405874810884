import { Observable } from 'rxjs';

export class Rol {
  id: number;
  nombre: string;
}

export abstract class RolData {
  abstract getList():  Observable<Rol[]>;
}
