import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'highlighter'
})
export class HighlighterPipe implements PipeTransform {
  safeHtml: SafeHtml;
  constructor(private sanitizer: DomSanitizer) {

  }

  transform(value: any, args: any, type: string): unknown {
    if(!args) return value;
    if(type==='full'){
      const re = new RegExp("\\b("+args+"\\b)", 'igm');
      value = value.changingThisBreaksApplicationSecurity.replace(re, '<span class="highlighted-text">$1</span>');
    }
    else {
      const re = new RegExp(args, 'igm');
      value = value.changingThisBreaksApplicationSecurity.replace(re, '<span class="highlighted-text">$&</span>');
    }

    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(value);
    return this.safeHtml;
  }

}
