<ng-container *transloco="let t; scope: 'manual'">
  <div class="row">
    <div class="col-xxl-12">
      <div class="row">
        <div class="col-xxl-3">
          <div class="container-indice" *ngIf="mostrarManual">
            <nb-card class="card-search-bar">
              <nb-card-body>
                <div class="row">
                  <div class="container-pesquisar">
                    <label class="label-pesquisar input-label large-width">{{ t('manual.pesquisar') }}</label>
                    <input (clear)="limparBusca()" (change)="countWords()" [(ngModel)]="searchText" type="search" class="input-textbox large-width input-pesquisar" nbInput>
                  </div>
                  <div *ngIf="mostrarResultados" class="row-resultado-search">
                    <span class="resultados-search">{{countResultados}} {{t('manual.resultados-encontrados')}}</span>
                    <a href="#" (click)="backWordSearch()"><i class="fas fa-arrow-up icon-resultado-search"></i></a>
                    <a href="#" (click)="nextWordSearch()"><i class="fas fa-arrow-down icon-resultado-search"></i></a>
                    <i></i>
                  </div>
                  <div *ngIf="mostrarSemResultados" class="row-resultado-search">
                    <span class="resultados-search">{{t('manual.sem-resultados-encontrados')}}</span>
                    <i></i>
                  </div>
                </div>
              </nb-card-body>
            </nb-card>
            <nb-card class="card-indice">
              <nb-card-header class="titulo-indice">
                <div class="row-titulo-indice">
                  {{ t('manual.secoesManual') }}
                </div>
              </nb-card-header>
              <nb-card-body>
                <ul>
                  <li class="closed" *ngFor="let item of secoes; index as i">
                    <a (click)="scrollToSecao($event)" data-attr-secao="#secao_{{i+1}}" href="#secao_{{i+1}}" title="{{item.titulo}}">{{item.titulo}}</a>
                  </li>
                </ul>
              </nb-card-body>
            </nb-card>
          </div>

        </div>
        <div class="col-xxl-9">
          <nb-card>
            <nb-card-header>
              Manual {{rolAtual}}
            </nb-card-header>
            <nb-card-body>
              <div class="row">
                <div *ngIf="mostrarManual" id="manualContent" [innerHtml]="safeHtml | highlighter:searchText:'full'"></div>
              </div>
            </nb-card-body>
          </nb-card>
        </div>
      </div>
    </div>
  </div>
</ng-container>
