import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Rol, RolData } from '../models/rol';
import { UrlService } from '../../../@core/services/urlService/url.service';
import { HttpCacheableService } from '../../../@core/services/cachingService/http-cacheable.service';

@Injectable()
export class RolService extends RolData {
    private apiRoute: string;
    constructor(private urlService: UrlService, private http: HttpClient, private httpCacheable: HttpCacheableService) {
        super();
        this.apiRoute = `${this.urlService.getBaseUrl()}Rol/`;
    }

    getList(): Observable<Rol[]> {
        return this.httpCacheable.get<Rol[]>({ url: `${this.apiRoute}GetRoles`, cacheSecs: 7200 });
    }
}
