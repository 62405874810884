import { Component, OnInit, OnDestroy } from '@angular/core';
import { Config } from '../../@core/config/config';
import { NbDialogService } from '@nebular/theme';
import { ManualService } from '../admin/services/manual.service';
import { RolService } from '../admin/services/rol.service';
import { TranslocoService } from '@ngneat/transloco';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Rol } from '../admin/models/rol';
import { UsuarioService } from '../admin/services/usuario.service';
import { Manual, Conteudos, Secoes } from '../admin/models/manual';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'ngx-manual-usuario',
  templateUrl: './manual-usuario.component.html',
  styleUrls: ['./manual-usuario.component.scss']
})
export class ManualUsuarioComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();

  rolList: Rol[];
  conteudos: Conteudos[] = [];
  secoesManual: string[] = [];
  secoes: Secoes[] = [];
  loading: boolean = true;
  manualUser: any;
  titulo: string;
  idiomaAtual: string;
  manualAtualContent: Manual;
  rolAtual: string;
  searchTerm: string = "";
  caseSensitive: any;
  searchText = '';
  mostrarManual: boolean = false;
  mostrarResultados: boolean = false;
  mostrarSemResultados: boolean = false;
  countResultados: number = 0;
  countWordAtual: number = 0;
  conteudoComIds: string = "";
  safeHtml: SafeHtml;

  constructor(private dialogService: NbDialogService,
    private manualService: ManualService,
    private rolService: RolService,
    private userService: UsuarioService,
    private i18nService2: TranslocoService,
    private sanitizer: DomSanitizer,
    i18nService: TranslocoService) {
    i18nService.selectTranslation('manual')
      .pipe(takeUntil(this.destroy$))
      .subscribe(i18n => {
        this.loading = true;
        this.titulo = i18n.titulo;
        this.manualUser = i18n;
        if (this.rolList != undefined && this.rolList.length > 0) {
          this.idiomaAtual = this.i18nService2.getActiveLang().toUpperCase();
          this.getManualAtual();
          this.searchText = "";
          this.mostrarSemResultados = false;
          this.mostrarResultados = false;
          this.countResultados = 0;
          this.countWordAtual = 0;
        }
        setTimeout(() => {
          this.loading = false;
        }, 30);
      });
  }

  ngOnInit(): void {
    this.idiomaAtual = this.i18nService2.getActiveLang().toUpperCase();
    this.rolAtual = sessionStorage.getItem(Config.LocalStorage.rolUsuarioLogueado);
    this.getRoles();
  }

  private getRoles() {
    this.rolService.getList().subscribe(
      data => {
        this.rolList = data;
        this.getManualAtual();
      });
  }

  private countWords() {
    var qtdSpan = document.getElementsByClassName("highlighted-text")
    this.countResultados = qtdSpan.length;
    if (this.countResultados > 0) {
      this.mostrarResultados = true;
      this.mostrarSemResultados = false;
    } else if (this.countResultados == 0 && this.searchText == "") {
      this.mostrarResultados = false;
      this.mostrarSemResultados = false;
      this.countWordAtual = 0;
    } else {
      this.mostrarSemResultados = true;
      this.mostrarResultados = false;
      this.countResultados = 0;
      this.countWordAtual = 0;
    }
  }

  private scrollToSecao(event) {
    event.preventDefault();
    setTimeout(() => {
      var id = event.target.href.substring(event.target.href.indexOf("#") + 1)
      var reference = document.getElementById(id)
      reference.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 150)
  }

  private backWordSearch() {
    event.preventDefault();
    var qtdSpan = document.getElementsByClassName("highlighted-text")[this.countWordAtual]
    qtdSpan.scrollIntoView({ behavior: 'smooth', block: 'center' })

    if (this.countWordAtual >= 1)
      this.countWordAtual -= 1;
  }

  private nextWordSearch() {
    event.preventDefault();
    var qtdSpan = document.getElementsByClassName("highlighted-text")[this.countWordAtual]
    qtdSpan.scrollIntoView({ behavior: 'smooth', block: 'center' })

    if (this.countWordAtual < this.countResultados - 1)
      this.countWordAtual += 1;
  }

  private getManualAtual() {
    this.conteudoComIds = '';
    this.secoes = [];
    this.manualAtualContent = {
      conteudo: '<div class=infoManual>' + this.manualUser.manualNaoExiste + '</div>',
      id: 1,
      idioma: this.idiomaAtual,
      rol: null,
      rolId: this.rolList.find(x => x.nombre == this.rolAtual).id
    };

    this.manualService.getByRolAndIdioma(this.rolList.find(x => x.nombre == this.rolAtual).id, this.idiomaAtual).subscribe(data => {
      if (data != undefined && data != null) {
        data.conteudo.split("<h1").forEach((val, index) => {
          index++;
          val += "<h1 id=\"secao_" + index + "\"";
          this.conteudoComIds += val;
        })
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.conteudoComIds);
        this.manualAtualContent = data;
        this.splitConteudo();
        this.mostrarManual = true;
      }
      else {
        this.manualAtualContent = {
          conteudo: '<div class=infoManual>' + this.manualUser.manualNaoExiste + '</div>',
          id: 1,
          idioma: this.idiomaAtual,
          rol: null,
          rolId: this.rolList.find(x => x.nombre == this.rolAtual).id
        };
        this.mostrarManual = true;
        this.safeHtml = this.sanitizer.bypassSecurityTrustHtml('<div class=infoManual>' + this.manualUser.manualNaoExiste + '</div>');
      }
    });
  }

  private splitConteudo() {
    this.manualAtualContent.conteudo.split("<h1").forEach(val => {
      if (val.substring(0, val.indexOf("</h1>")) != "") {
        val += "<h1";
        this.secoes.push({ titulo: this.removeHTMLTags(val.substring(0, val.indexOf("</h1>") + 1)), subtitulos: val.split("<h2>") })

      }
    })
    this.secoes.forEach(val => {
      val.titulo = val.titulo.slice(val.titulo.indexOf("\">") + 2, val.titulo.indexOf("<"))
      val.subtitulos = val.subtitulos.slice(1, val.subtitulos.length);
      val.subtitulos = val.subtitulos.map(val => val.substring(0, val.indexOf("</h2>")).slice(val.indexOf(">") + 1, val.indexOf("</font>")))
    })
  }

  removeHTMLTags(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.innerText;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
