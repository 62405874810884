import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard } from './@core/auth/auth.guard';
import { LandingComponent } from './landing/landing.component';
import { ActivacionUsuarioComponent } from './pages/activacion-usuario/activacion-usuario.component';
import { ManualUsuarioComponent } from './pages/manual-usuario/manual-usuario.component';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
    canActivate: [AuthGuard],
    data: {roles: []},
  },
  {
    path: 'auth',
    loadChildren: () => import('./@core/auth/auth.module')
      .then(m => m.AuthModule),
  },
  {
    path:'bienvenido',
    component: LandingComponent,
  },
  {
    path:'activar-usuario',
    component: ActivacionUsuarioComponent,
  },
  {
    path: 'manual-usuario',
    component: ManualUsuarioComponent,
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
